import { useId } from 'react';

import { FieldWrapper } from '../FieldWrapper';

import { LegacyTextFieldInput } from './LegacyTextFieldInput';

export function LegacyTextField({
  className,
  error,
  inputMode,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  sublabel,
  rightAddOn,
  tabIndex,
  type = 'text',
  value,
}: {
  className?: string;
  error?: string;
  inputMode?: 'numeric';
  label?: string;
  maxLength?: number;
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  rightAddOn?: string;
  sublabel?: string;
  tabIndex?: number;
  type?: 'email' | 'text';
  value: string;
}) {
  const id = useId();

  return (
    <FieldWrapper className={className} error={error} id={id} prompt={label}>
      <LegacyTextFieldInput
        hasError={error !== undefined}
        inputMode={inputMode}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rightAddOn={rightAddOn}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
      {sublabel && (
        <p className="mt-1 text-sm text-gray-500">
          {sublabel}
        </p>
      )}
    </FieldWrapper>
  );
}
