import { useFormContext } from '../contexts'
import { BloodPressureFieldUiProps } from '../types'
import { FieldSubprompt } from './FieldSubprompt';

import { FormLabel } from './FormLabel';
import { LegacySelectField } from './legacy/LegacySelectField';

export function BloodPressureField({
  diastolic,
  id,
  prompt,
  questionId,
  subprompt,
  systolic,
}: BloodPressureFieldUiProps) {
  const { getError, getResponse, setResponse } = useFormContext();

  const systolicError = getError(questionId.systolic);
  const diastolicError = getError(questionId.diastolic);
  const systolicValue = getResponse(questionId.systolic);
  const diastolicValue = getResponse(questionId.diastolic);

  if ((systolicValue !== null && typeof systolicValue !== 'number')
      || (diastolicValue !== null && typeof diastolicValue !== 'number')) {
    throw new Error();  // TODO
  }

  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex flex-col gap-y-2">
        <FormLabel
          id={id}
          hasError={systolicError !== undefined || diastolicError !== undefined}
          label={prompt}
        />
        <FieldSubprompt subprompt={subprompt} />
        <div className="flex flex-row gap-x-3">
          <LegacySelectField<number>
            className="min-w-24"
            error={systolicError}
            onChange={(systolic) => {
              setResponse(questionId.systolic, systolic);
            }}
            options={[
              {
                label: '90',
                value: 90,
              },
              {
                label: '95',
                value: 95,
              },
              {
                label: '100',
                value: 100,
              },
              {
                label: '105',
                value: 105,
              },
              {
                label: '110',
                value: 110,
              },
              {
                label: '115',
                value: 115,
              },
              {
                label: '120',
                value: 120,
              },
              {
                label: '125',
                value: 125,
              },
              {
                label: '130',
                value: 130,
              },
              {
                label: '135',
                value: 135,
              },
              {
                label: '140',
                value: 140,
              },
              {
                label: '145',
                value: 145,
              },
              {
                label: '150',
                value: 150,
              },
              {
                label: '155',
                value: 155,
              },
              {
                label: '160',
                value: 160,
              },
              {
                label: '165',
                value: 165,
              },
              {
                label: '170',
                value: 170,
              },
              {
                label: '175',
                value: 175,
              },
              {
                label: '180',
                value: 180,
              },
              {
                label: '185',
                value: 185,
              },
              {
                label: '190',
                value: 190,
              },
              {
                label: '195',
                value: 195,
              },
              {
                label: '200',
                value: 200,
              },
              {
                label: '205',
                value: 205,
              },
              {
                label: '210',
                value: 210,
              },
              {
                label: '215',
                value: 215,
              },
              {
                label: '220',
                value: 220,
              },
            ]}
            sublabel={systolic.label}
            value={systolicValue}
          />
          <span className="text-2xl">/</span>
          <LegacySelectField<number>
            className="min-w-24"
            error={diastolicError}
            onChange={(diastolic) => {
              setResponse(questionId.diastolic, diastolic);
            }}
            options={[
              {
                label: '60',
                value: 60,
              },
              {
                label: '65',
                value: 65,
              },
              {
                label: '70',
                value: 70,
              },
              {
                label: '75',
                value: 75,
              },
              {
                label: '80',
                value: 80,
              },
              {
                label: '85',
                value: 85,
              },
              {
                label: '90',
                value: 90,
              },
              {
                label: '95',
                value: 95,
              },
              {
                label: '100',
                value: 100,
              },
              {
                label: '105',
                value: 105,
              },
              {
                label: '110',
                value: 110,
              },
              {
                label: '115',
                value: 115,
              },
              {
                label: '120',
                value: 120,
              },
              {
                label: '125',
                value: 125,
              },
              {
                label: '130',
                value: 130,
              },
              {
                label: '135',
                value: 135,
              },
              {
                label: '140',
                value: 140,
              },
            ]}
            sublabel={diastolic.label}
            value={diastolicValue}
          />
        </div>
      </div>
    </div>
  );
}