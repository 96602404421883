import { Card, JobRoleSummary, useAppContext } from '@/features/app';
import { Language, useI18nContext } from '@/features/i18n';

export function RmeApproval({
  documentUrl,
  showNumber,
}: {
  documentUrl: string;
  showNumber: boolean;
}) {
  const { appInitializer } = useAppContext();
  const { language } = useI18nContext();

  const rmeModule = appInitializer.jobRole.modules.respiratorMedicalEvaluation;

  const multipleRespirators = rmeModule !== undefined && rmeModule.respiratorTypeUsages.length > 1;

  return (
    <Card>
      {showNumber && (
        <div className="text-center">
          {{
            [Language.ENGLISH]: 'Result 1 of 2',
            [Language.SPANISH]: 'Resultado 1 de 2',
          }[language]}
        </div>
      )}
      <div className="text-lg font-semibold text-center mb-2">
        {{
          [Language.ENGLISH]: 'Respirator Medical Evaluation',
          [Language.SPANISH]: 'Evaluación Médica para un Respirador',
        }[language]}
      </div>
      <h2 className="text-3xl font-semibold text-center text-green-600 mb-8">
        {{
          [Language.ENGLISH]: 'APPROVED',
          [Language.SPANISH]: 'APROBADO',
        }[language]}
      </h2>
      <p className="mb-4">
        {{
          [Language.ENGLISH]: `
            Based on the answers you provided, you have been medically approved
            for use of the ${multipleRespirators ? 'respirators' : 'respirator'}
            below while working. Your approval expires in one year, but you may
            need an evaluation sooner if your health or job role changes.
            Contact your supervisor (or the person who instructed you to
            complete this questionnaire) if you are unsure of how to proceed or
            if you have any changes to your health or issues using a respirator.
            If you have questions about the result of your evaluation, please
            contact
          `,
          [Language.SPANISH]: `
            En base a las respuestas que ha proporcionado, se le ha aprobado
            médicamente para el uso de los respiradores que se indican a
            continuación mientras trabaja. Su aprobación vence en un año, pero
            es posible que necesite una evaluación antes si su salud o su
            función laboral cambian. Póngase en contacto con su supervisor (o
            con la persona que le indicó que rellenara este cuestionario) si no
            está seguro de cómo proceder o si se produce algún cambio en su
            salud o tiene problemas para utilizar un respirador. Si tiene
            preguntas sobre el resultado de su evaluación, póngase en contacto
            con
          `,
        }[language]}
        <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
        {{
          [Language.ENGLISH]: '.',
          [Language.SPANISH]: '.',
        }[language]}
      </p>
      <JobRoleSummary />
      <div className="border-t-2 border-gray-100 pt-4 mt-4">
        {{
          [Language.ENGLISH]: `
            An approval document will be sent to the email address you provided
            on this form. Click below to download a printable copy of your
            approval document now:
          `,
          [Language.SPANISH]: `
            Se le enviará un documento de aprobación a la dirección de correo
            electrónico que proporcionó en este formulario. Haga clic a
            continuación para descargar ahora a una copia imprimible de su
            documento de aprobación:
          `,
        }[language]}
      </div>
      <div className="mt-4">
        <a href={documentUrl} rel="noreferrer" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-primary mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
        </a>
      </div>
    </Card>
  );
}
