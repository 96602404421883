import * as Sentry from '@sentry/react';
import { formatISO, sub } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';

import { Button } from '@/components/Button';
import {
  AppStage,
  Card,
  DrawerContent,
  JobRoleSummary,
  LocationStub,
  Page,
  SubmitError,
  VerticalSpacer,
  useAppContext,
} from '@/features/app';
import {
  DropdownDirection,
  LegacyDateField,
  LegacyInlineRadioField,
  LegacySelectField,
  LegacyTextField,
  Responses,
} from '@/features/form';
import { Language, useI18nContext } from '@/features/i18n';
import { isValidDate } from '@/lib/validation';

import { GenderIdentity, NameSuffix, Sex } from '../constants';
import { InitializeQuestionnaireResponse, PiiStageErrors, PiiStageResponses } from '../types';

export function PiiPage({
  accessCode,
  setLocation,
  setResponsePreloads,
  setQuestionnairePrn,
}: {
  accessCode: string;
  setLocation: Dispatch<SetStateAction<LocationStub>>;
  setResponsePreloads: Dispatch<SetStateAction<Responses>>;
  setQuestionnairePrn: Dispatch<SetStateAction<string | null>>;
}) {
  const { appInitializer, setAppStage, setDrawerContent } = useAppContext();
  const { language } = useI18nContext();

  const autoselectedLocation = appInitializer.locations.length === 1
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion --
     * locations[0] is guaranteed to exist by the check above
     */
    ? appInitializer.locations[0]!
    : null;

  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<PiiStageErrors>({});
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState<PiiStageResponses>({
    dateOfBirth: '',
    emailAddress: '',
    firstName: '',
    genderIdentity: null,
    lastName: '',
    location: autoselectedLocation !== null
      ? {
        prn: autoselectedLocation.prn,
      }
      : null,
    middleName: '',
    nameSuffix: null,
    phoneNumber: '',
    sex: null,
    ssnLast4: '',
  });

  async function trySubmit() {
    const _errors: PiiStageErrors = {};

    if (responses.firstName.trim().length === 0) {
      _errors.firstName = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (responses.firstName.includes('#')) {
      _errors.firstName = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.middleName.includes('#')) {
      _errors.middleName = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.lastName.trim().length === 0) {
      _errors.lastName = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (responses.lastName.includes('#')) {
      _errors.lastName = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    const today = new Date();
    const max = formatISO(sub(today, { years: 14 }), { representation: 'date' });
    const min = formatISO(sub(today, { years: 100 }), { representation: 'date' });

    if (!isValidDate({ date: responses.dateOfBirth, max, min })) {
      _errors.dateOfBirth = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.sex === null) {
      _errors.sex = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    }

    const ssnRegex = /^[0-9]{4}$/;

    if (responses.ssnLast4.trim().length === 0) {
      _errors.ssnLast4 = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (!ssnRegex.test(responses.ssnLast4) || responses.ssnLast4 === '0000') {
      _errors.ssnLast4 = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.location === null) {
      _errors.location = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    }

    const emailRegex = /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;

    if (responses.emailAddress.trim().length === 0) {
      _errors.emailAddress = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (!emailRegex.test(responses.emailAddress)) {
      _errors.emailAddress = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    const phoneRegex = /^[0-9]{10}$/;

    if (responses.phoneNumber.trim().length === 0) {
      _errors.phoneNumber = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (!phoneRegex.test(responses.phoneNumber)) {
      _errors.phoneNumber = {
        [Language.ENGLISH]: 'Phone number is invalid (10 digits).',
        [Language.SPANISH]: 'Número de teléfono es inválido (10 dígitos).',
      };
    }

    setErrors(_errors);

    if (Object.keys(_errors).length === 0) {
      setError(false);
      setLoading(true);

      const { location, ...employeeFields } = responses;

      try {
        const result = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/questionnaires/initialize`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
              employee: employeeFields,
              accessCode: { accessCode },
              location,
            }),
          },
        );

        const initializeResponse = await result.json() as InitializeQuestionnaireResponse;

        if (initializeResponse.entryAllowed) {
          // This should never happen, guaranteed by validation above
          if (location === null) {
            throw new Error();  // TODO
          }

          const selectedLocation = appInitializer.locations.find((l) => l.prn === location.prn);

          // This should also never happen, because where else did they get
          // that location?
          if (selectedLocation === undefined) {
            throw new Error();  // TODO
          }

          setLocation({
            name: selectedLocation.name,
          });
          setResponsePreloads({
            '7f222d65-4122-46da-a4c8-6f28ed45f4e9': responses.genderIdentity,
            'dateOfBirth': responses.dateOfBirth,
          });
          setQuestionnairePrn(initializeResponse.questionnairePrn);
          setAppStage(AppStage.QUESTIONNAIRE);
        } else {
          setAppStage(AppStage.PROHIBITED);
          setLoading(false);
        }
      } catch (err) {
        Sentry.captureException(err);
        setError(true);
        setLoading(false);
      }
    } else {
      const errors = document.getElementsByClassName('has-error');

      if (errors.length > 0) {
        const firstError = errors[0] as HTMLElement;

        scrollTo({
          behavior: 'smooth',
          top: firstError.offsetTop - 74,
        });
      }
    }
  }

  const rmeModule = appInitializer.jobRole.modules.respiratorMedicalEvaluation;

  return (
    <Page
      title={{
        [Language.ENGLISH]: 'Your Personal Information',
        [Language.SPANISH]: 'Su Información Personal',
      }[language]}
    >
      <VerticalSpacer>
        <div>
          {{
            [Language.ENGLISH]: 'Please provide your personal information below so that we may identify you and distinguish you from other employees. Your information is never shared with third parties without your consent. ',
            [Language.SPANISH]: 'Por favor, facilítenos a continuación su información personal para que podamos identificarle y distinguirle de otros empleados. Su información nunca se comparte con terceros sin su consentimiento. ',
          }[language]}
          <a className="text-primary hover:underline cursor-pointer" onClick={() => {
            setDrawerContent(DrawerContent.PRIVACY_POLICY);
          }}>
            {{
              [Language.ENGLISH]: 'Click here',
              [Language.SPANISH]: 'Haga click aquí',
            }[language]}
          </a>
          {{
            [Language.ENGLISH]: ' to view our Privacy Policy.',
            [Language.SPANISH]: ' para consultar nuestra Política de Privacidad.',
          }[language]}
        </div>
        <Card title={{
          [Language.ENGLISH]: 'Your Job Role & Respirators',
          [Language.SPANISH]: 'Su Función Laboral y Los Respiradores',
        }[language]}>
          <div className="flex flex-col gap-4">
            {{
              [Language.ENGLISH]: `The following is a summary of the type${rmeModule !== undefined && rmeModule.respiratorTypeUsages.length > 1 ? 's' : ''} of respirator you are being medically evaluated for today. If this information does not look correct, please contact your supervisor or hiring point of contact.`,
              [Language.SPANISH]: `A continuación, encontrará un resumen ${rmeModule !== undefined && rmeModule.respiratorTypeUsages.length > 1 ? 'de los tipos de respirador para los' : 'del tipo de respirador para lo'} que está siendo evaluado médicamente hoy. Si esta información no le parece correcta, póngase en contacto con su supervisor o punto de contacto de contratación.`,
            }[language]}
            <JobRoleSummary locationName={autoselectedLocation?.name} />
          </div>
        </Card>
        <Card title={{
          [Language.ENGLISH]: 'Basic Details',
          [Language.SPANISH]: 'Detalles Básicos',
        }[language]}>
          <div className="grid grid-cols-3 gap-x-6 gap-y-8 mb-8 sm:grid-cols-11">
            <LegacyTextField
              className="col-span-3"
              error={errors.firstName?.[language]}
              label={{
                [Language.ENGLISH]: 'First Name',
                [Language.SPANISH]: 'Nombre',
              }[language]}
              maxLength={64}
              name="firstName"
              onChange={(firstName) => {
                setResponses({ ...responses, firstName });
              }}
              tabIndex={0}
              value={responses.firstName}
            />
            <LegacyTextField
              className="col-span-3"
              error={errors.middleName?.[language]}
              label={{
                [Language.ENGLISH]: 'Middle Name',
                [Language.SPANISH]: 'Segundo Nombre',
              }[language]}
              maxLength={64}
              name="middleName"
              onChange={(middleName) => {
                setResponses({ ...responses, middleName });
              }}
              tabIndex={0}
              value={responses.middleName}
            />
            <LegacyTextField
              className="col-span-2 sm:col-span-3"
              error={errors.lastName?.[language]}
              label={{
                [Language.ENGLISH]: 'Last Name',
                [Language.SPANISH]: 'Apellido',
              }[language]}
              maxLength={64}
              name="lastName"
              onChange={(lastName) => {
                setResponses({ ...responses, lastName });
              }}
              tabIndex={0}
              value={responses.lastName}
            />
            <LegacySelectField<NameSuffix>
              className="sm:col-span-2"
              label={{
                [Language.ENGLISH]: 'Suffix',
                [Language.SPANISH]: 'Sufijo',
              }[language]}
              onChange={(nameSuffix) => {
                setResponses({ ...responses, nameSuffix });
              }}
              opens={DropdownDirection.LEFT}
              options={[
                {
                  label: {
                    [Language.ENGLISH]: 'Jr.',
                    [Language.SPANISH]: 'Jr.',
                  }[language],
                  value: NameSuffix.JR,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'Sr.',
                    [Language.SPANISH]: 'Sr.',
                  }[language],
                  value: NameSuffix.SR,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'II',
                    [Language.SPANISH]: 'II',
                  }[language],
                  value: NameSuffix.II,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'III',
                    [Language.SPANISH]: 'III',
                  }[language],
                  value: NameSuffix.III,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'IV',
                    [Language.SPANISH]: 'IV',
                  }[language],
                  value: NameSuffix.IV,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'V',
                    [Language.SPANISH]: 'V',
                  }[language],
                  value: NameSuffix.V,
                },
              ]}
              value={responses.nameSuffix}
            />
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 mb-8 sm:grid-cols-3">
            <LegacyDateField
              error={errors.dateOfBirth?.[language]}
              label={{
                [Language.ENGLISH]: 'Date of Birth',
                [Language.SPANISH]: 'Fecha de Nacimiento',
              }[language]}
              onChange={(dateOfBirth) => {
                setResponses({ ...responses, dateOfBirth });
              }}
              value={responses.dateOfBirth}
            />
            <LegacyTextField
              error={errors.ssnLast4?.[language]}
              inputMode="numeric"
              label={{
                [Language.ENGLISH]: 'SSN (Last 4 Digits)',
                [Language.SPANISH]: 'SSN (4 Últimos Dígitos)',
              }[language]}
              maxLength={4}
              name="ssnLast4"
              onChange={(ssnLast4) => {
                ssnLast4 = ssnLast4.trim().replace(/[^0-9]/g, '');
                setResponses({ ...responses, ssnLast4 });
              }}
              tabIndex={0}
              value={responses.ssnLast4}
            />
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
            <LegacyInlineRadioField<Sex>
              error={errors.sex?.[language]}
              label={{
                [Language.ENGLISH]: 'Sex Assigned at Birth',
                [Language.SPANISH]: 'Sexo Asignado al Nacer',
              }[language]}
              onChange={(sex) => {
                setResponses({ ...responses, sex });
              }}
              options={[
                {
                  label: {
                    [Language.ENGLISH]: 'Male',
                    [Language.SPANISH]: 'Hombre',
                  }[language],
                  value: Sex.MALE,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'Female',
                    [Language.SPANISH]: 'Mujer',
                  }[language],
                  value: Sex.FEMALE,
                },
              ]}
              value={responses.sex}
            />
            <LegacySelectField<GenderIdentity>
              label={{
                [Language.ENGLISH]: 'Gender Identity',
                [Language.SPANISH]: 'Identidad de Género',
              }[language]}
              onChange={(genderIdentity) => {
                setResponses({ ...responses, genderIdentity });
              }}
              options={[
                {
                  label: {
                    [Language.ENGLISH]: 'Man',
                    [Language.SPANISH]: 'Hombre',
                  }[language],
                  value: GenderIdentity.MAN,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'Woman',
                    [Language.SPANISH]: 'Mujer',
                  }[language],
                  value: GenderIdentity.WOMAN,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'Transgender',
                    [Language.SPANISH]: 'Transgénero',
                  }[language],
                  value: GenderIdentity.TRANSGENDER,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'Non-binary / Non-conforming',
                    [Language.SPANISH]: 'No binario / No conforme',
                  }[language],
                  value: GenderIdentity.NONBINARY,
                },
                {
                  label: {
                    [Language.ENGLISH]: 'Prefer not to answer',
                    [Language.SPANISH]: 'Prefiero no contestar',
                  }[language],
                  value: GenderIdentity.PREFER_NOT_TO_ANSWER,
                },
              ]}
              value={responses.genderIdentity}
            />
          </div>
        </Card>
        {autoselectedLocation === null && (
          <Card title={{
            [Language.ENGLISH]: 'Location',
            [Language.SPANISH]: 'Ubicación',
          }[language]}>
            <LegacySelectField<string>
              error={errors.location?.[language]}
              label={{
                [Language.ENGLISH]: 'Your Work Location',
                [Language.SPANISH]: 'Ubicación de su Trabajo:',
              }[language]}
              onChange={(locationPrn) => {
                setResponses({
                  ...responses,
                  location: {
                    prn: locationPrn,
                  },
                });
              }}
              options={appInitializer.locations.map((location) => ({
                label: {
                  [Language.ENGLISH]: location.name,
                  [Language.SPANISH]: location.name,
                }[language],
                value: location.prn,
              }))}
              value={responses.location?.prn ?? null}
            />
          </Card>
        )}
        <Card title={{
          [Language.ENGLISH]: 'Contact Info',
          [Language.SPANISH]: 'Información de Contaco',
        }[language]}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
            <LegacyTextField
              error={errors.emailAddress?.[language]}
              label={{
                [Language.ENGLISH]: 'Email Address',
                [Language.SPANISH]: 'Dirección de Correo Electrónico',
              }[language]}
              name="emailAddress"
              onChange={(emailAddress) => {
                setResponses({ ...responses, emailAddress });
              }}
              value={responses.emailAddress}
            />
            <LegacyTextField
              error={errors.phoneNumber?.[language]}
              inputMode="numeric"
              label={{
                [Language.ENGLISH]: 'Phone Number',
                [Language.SPANISH]: 'Número de Teléfono',
              }[language]}
              maxLength={10}
              name="phoneNumber"
              onChange={(phoneNumber) => {
                setResponses({ ...responses, phoneNumber });
              }}
              value={responses.phoneNumber}
            />
          </div>
        </Card>
        {error && <SubmitError />}
        <div className="flex w-full justify-center mb-8">
          <Button disabled={loading} loading={loading} onClick={() => {
            void trySubmit();
          }}>
            {{
              [Language.ENGLISH]: loading ? 'Loading Questionnaire...' : 'Continue',
              [Language.SPANISH]: loading ? 'Cargando Cuestionario...' : 'Continuar',
            }[language]}
          </Button>
        </div>
      </VerticalSpacer>
    </Page>
  );
}
