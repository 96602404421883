import { Page, VerticalSpacer } from '@/features/app';
import { Language, useI18nContext } from '@/features/i18n';

import {
  MedicalSurveillanceEvaluationStatus,
  RespiratorMedicalEvaluationStatus,
} from '../../constants';
import { QuestionnaireResult } from '../../types';

import { GcdmseClearance } from './GcdmseClearance';
import { InProgressResult } from './InProgressResult';
import { RmeApproval } from './RmeApproval';

export function ResultPage({
  result,
}: {
  result: QuestionnaireResult;
}) {
  const { language } = useI18nContext();

  const gcdmseResult = result.groundCannabisDustMedicalSurveillanceEvaluation;
  const rmeResult = result.respiratorMedicalEvaluation;

  const inProgress = (
    gcdmseResult?.status === MedicalSurveillanceEvaluationStatus.PENDING_REVIEW
    || rmeResult?.status === RespiratorMedicalEvaluationStatus.PENDING_REVIEW
  );

  return (
    <Page title={{
      [Language.ENGLISH]: 'Your Result',
      [Language.SPANISH]: 'Su Resultado',
    }[language]}>
      <VerticalSpacer>
        {inProgress ? (
          <InProgressResult />
        ) : (
          <>
            {rmeResult !== undefined && (
              <RmeApproval
                documentUrl={rmeResult.documentUrl}
                showNumber={gcdmseResult !== undefined}
              />
            )}
            {gcdmseResult !== undefined && (
              <GcdmseClearance documentUrl={gcdmseResult.documentUrl} />
            )}
          </>
        )}
      </VerticalSpacer>
    </Page>
  );
}
